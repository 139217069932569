* {
  margin: 0px;
  padding: 0px;
}

.Typist .Cursor {
  display: inline-block; }
  .Typist .Cursor--blinking {
    opacity: 1;
    animation: blink 1s linear infinite; }

@keyframes blink {
  0% {
    opacity: 1; }
  50% {
    opacity: 0; }
  100% {
    opacity: 1; } }

.navbar {
  margin-top: -16px;
}

.fade {
  animation: fade-in-keyframes 1.3s;
}

@keyframes fade-in-keyframes {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.menu {
  position: sticky;
  background-color: var(--off-white);
  top: 15px;
  height: 25px;
  padding-left: 15px;
  z-index: 1000;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.15);
}

.navbar ul {
  list-style: none;
  text-align: right;
  margin-top: -18px;
}

.navbar ul li {
  color: white;
  font-size: 0.9rem;
  margin-right: 1.8%;
  display: inline-block;
  cursor: pointer;
  opacity: 0.8;
  filter: drop-shadow(0.2rem 0.2rem 0.4rem rgb(149, 149, 149));
}

.navbar ul li:hover {
  opacity: 1;
  filter: drop-shadow(0.2rem 0.2rem 0.4rem rgb(255, 246, 208));
}

navbar ul li > .active,
.navbar ul li:hover {
  border-bottom: 2px solid #fff;
}

.home-section {
  height: 104vh;
  background-image: url('./media/220675.jpg');
  background-position: center, center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.9;
}

.about-section {
  background: linear-gradient(
    to top,
    rgb(244, 247, 255) 0%,
    rgb(204, 209, 223) 40%,
    rgb(154, 173, 211) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  height: 100vh;
}

.projects-section {
  height: 100vh;
  background: linear-gradient(
    to top,
    rgb(254, 255, 244) 0%,
    rgb(229, 225, 215) 40%,
    rgb(214, 198, 179) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.skills-section {
  height: 100vh;
  background: linear-gradient(
    to top,
    rgb(255, 255, 255) 0%,
    rgb(231, 231, 231) 40%,
    rgb(161, 161, 161) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.experience-section {
  height: 100vh;
  background: linear-gradient(
    to top,
    rgb(248, 248, 248) 0%,
    rgb(184, 216, 171) 40%,
    rgb(159, 201, 158) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

/* .contact-section {
  height: 100vh;
  background: linear-gradient(
    to top,
    rgb(248, 248, 248) 0%,
    rgb(219, 210, 199) 40%,
    rgb(233, 190, 141) 100%
  );
} */

.type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -47px;
  margin-left: 60px;
  margin-bottom: 0px;
  min-height: 65vh;
}

/* iPhone X */
@media only screen and (max-width: 750px) {
  .type {
    padding-left: 0;
    padding-top: 5%;
    padding-right: 6%;
  }
}

home-text {
  font-family: 'Playfair Display', serif;
  font-size: 1.8rem;
  color: rgb(209, 220, 251);
  display: inline;
  padding: 0;
  margin: 0;
}

.a {
  font-family: 'Spectral', serif;
}

.about-paper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  padding-top: 35px;
  margin-right: 45px;
  margin-bottom: 0px;
  min-height: 60vh;
  margin-left: 20%;
  width: 60vw;
}

.section-title {
  width: max-content;
  justify-content: center;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3px;
  padding: 10px 18px;
  border: 1px solid;
  color: black
}

.container {
  padding-top: 65px;
  padding-left: 25px;
}

.center {
  padding-top: 4%;
}

.project-header {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  caption-side: bottom;
  padding-top: 2%;
  text-align: center;
  color: black
}

.project-description {
  caption-side: bottom;
  padding-top: 1%;
  text-align: center;
  color: black
}

.project-stack {
  text-align: center;
  color: black;
  padding-bottom: 4px;
}

.project-links {
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 500;
  caption-side: bottom;
  text-align: center;
  padding-bottom: 6px;
}

.realimage {
  /* opacity: 0.7; */
  /* -webkit-transition: 0.3s ease-in-out; */
  /* transition: 0.3s ease-in-out; */
  filter: drop-shadow(0.2rem 0.2rem 0.4rem rgb(88, 88, 88, 1));
  opacity: 0.9;
}

svg {
  opacity: 0.8;
  filter: drop-shadow(0.2rem 0.2rem 0.4rem rgb(149, 149, 149));
}

svg:hover {
  opacity: 1;
  filter: drop-shadow(0.2rem 0.2rem 0.4rem rgb(255, 246, 208));
}

.centerskills {
  margin-top: -1%;
  padding-left: 9%;
}

@media only screen and (max-width: 950px) {
  .centerskills {
    padding-top: 5%;
    /* padding-left: 13%; */
    /* padding-right: 4%; */
  }
}

@media only screen and (max-width: 600px) {
  .centerskills {
    padding-left: 10.5%;
    padding-right: 5%;
  }
}

/* experience section */
.experience-container {
  padding-top: 6%;
  /* padding-left: 17%;
  padding-right: 8%; */
  margin:auto;
  text-align: center;
}

@media only screen and (max-width: 950px) {
  .experience-container {
    padding-top: 18%;
    margin: auto;
    text-align: center;
  }
}

.contact-container {
  /* padding-top: 4%; */
  padding-left: 17%;
  padding-right: 8%;
  text-align: center;
}

.contact-message {
  justify-content: center;
  text-transform: uppercase;
  word-wrap: break-word;
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 2px;
  word-wrap: break-word;
  padding-top: 5%;
  padding-right: 5%;
  padding-left: 39%;
  color: black
  /* border: 1px solid; */
}

@media only screen and (max-width: 950px) {
  .contact-message {
    padding-top: 7%;
    padding-left: 29%;
    padding-right: 15%;
  }
}

@media only screen and (max-width: 950px) {
  .contact-container {
    /* padding-top: 4%; */
    padding-left: 10%;
    padding-right: 8%;
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .contact-message {
    display: inline-flex;
    padding-left: 21%;
    padding-top: 15%;
    padding-bottom: 5%;
    padding-right: 20%;
  }
}

@media only screen and (max-width: 600px) {
  .contact-container {
    padding-top: 4%;
    padding-left: 10%;
    padding-right: 8%;
    text-align: center;
  }
}

/* span {
  font-family: 'Spectral', serif;
  font-size: 1em;
  font-weight: 500;
  color: rgb(0, 0, 0);
} */

.contact-svg {
  font-size: 0.9rem;
  display: inline;
  cursor: pointer;
  margin-left: 20px;
}

@media only screen and (max-width: 950px) {
  .navbar ul li {
    color: white;
    font-size: 0.8rem;
    margin-right: 1.8%;
    display: inline-block;
    cursor: pointer;
    opacity: 0.8;
    filter: drop-shadow(0.2rem 0.2rem 0.4rem rgb(149, 149, 149));
  }
}

@media only screen and (max-width: 950px) {
  .contact-svg {
    font-size: 0.8rem;
    display: inline;
    cursor: pointer;
    margin-left: 16px;
  }
}

.arrow{
    position: absolute;
    top: 92%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.arrow span{
    display: block;
    width: 10px;
    height: 10px;
    border-bottom: 1.5px solid rgb(209, 220, 251);
    border-right: 1.5px solid rgb(209, 220, 251);
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;
}

.arrow span:nth-child(2){
    animation-delay: -0.2s;
}
.arrow span:nth-child(3){
    animation-delay: -0.4s;
}

@keyframes animate {
    0%{
        opacity: 0;
        transform: rotate(45deg) translate(-20px,-20px);
    }
    50%{
        opacity: 1;
    }
    100%{
        opacity: 0;
        transform: rotate(45deg) translate(20px,20px);
    }
}
